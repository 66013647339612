<template>
    <div class="flex justify-content-center">
        <div class="col-12 lg:col-10 grid" >
            <!-- <div class="col-12 md:col-6 lg:col-4 " style="display:grid">
                <Button label="Credit Card" class="m-3" style="width: 190px; justify-self:center" @click="payCC">
                  <div class='grid justify-content-center'>
                    <i v-if='authorizing' class="pi pi-spin pi-spinner col-12" style="font-size: 8rem"></i>
                    <i v-else class="pi pi-credit-card col-12" style="font-size: 8rem"></i>
                    <span class='col-12' >Credit Card</span>
                    </div>
                </Button>
            </div> -->
            <div class="col-12 md:col-6 lg:col-4 " style="display:grid">
                 <Button :disabled="!this.getCustomer" label="Card on File" class="m-3" style="width: 190px; justify-self:center" @click="payCOF">
                   <div class='grid justify-content-center'>
                    <i class="pi pi-folder-open col-12" style="font-size: 8rem"></i>
                    <span class='col-12'>Card on File</span>
                    </div>
                </Button>
            </div>
            <div class="col-12 md:col-6 lg:col-4 " style="display:grid">
                <Button label="Manual Card Entry" class="m-3" style="width: 190px; justify-self:center" @click="payCardNotPresent">
                  <div class='grid justify-content-center'>
                    <i class="pi pi-pencil col-12" style="font-size: 8rem"></i>
                    <span class='col-12'>Manual Card Entry</span>
                    </div>
                </Button>
            </div>
            <!-- <div class="col-12 md:col-6 lg:col-4 " style="display:grid">
                <Button label="Cash" class="m-3" style="width: 190px; justify-self:center" @click="this.displayCashModal = true">
                  <div class='grid justify-content-center'>
                    <i class="pi pi-money-bill col-12" style="font-size: 8rem"></i>
                    <span class='col-12'>Cash</span>
                  </div>
                </Button>
            </div> -->
            <!-- <div class="col-12 md:col-6 lg:col-4 " style="display:grid">
                <Button label="Check" class="m-3" style="width: 190px; justify-self:center" @click="this.displayCheckModal = true">
                  <div class='grid justify-content-center'>
                    <i class="pi pi-wallet col-12" style="font-size: 8rem"></i>
                    <span class='col-12'>Check</span>
                    </div>
                </Button>
            </div> -->
            <div class="col-12 md:col-6 lg:col-4 " style="display:grid">
                <Button disabled label="ACH" class="m-3" style="width: 190px; justify-self:center" @click="payACH">
                  <div class='grid justify-content-center'>
                    <i class="pi pi-desktop col-12" style="font-size: 8rem"></i>
                    <span class='col-12'>e-Check</span>
                    </div>
                </Button>
            </div>
        </div>
    </div>
    <Dialog v-model:visible='displayIframe' modal>
      <iframe
        v-resize="{
        log: false,
        heightCalculationMethod: 'documentElementScroll',
        }"
        :src='payment.iframeurl'
        class="w-full"
        id="iframe"
        frameborder="0"
      >
      </iframe>
    <div
      class="sm:col-12 md:col-12 lg:col-12 text-center"
      v-if="
      this.success
      ">
      <b><span class="text-green-900">Credit Card Validation Succesful</span></b>
      <div class="p-inputgroup mt-2">
           <small class="p-inputgroup-addon font-semibold general-label-color" :style="{ width: '104px' }">Amount</small>
          <InputText
            autofocus
            v-model="this.amount"
            id="check"
            class="text-sm"    
          />
      </div>
    </div>
    <template #footer>
      <Button v-if="this.success" @click='submitPayment'>Submit</Button>
    </template>
    </Dialog>
    <Dialog v-model:visible="displayCOF" modal header="Select a credit card for payment">
        <DataTable :value="this.getCustomer.credit_card_no_items" responsiveLayout="stack" selectionMode="single" breakpoint="1279px" class="p-datatable-sm" @row-click="rowClicked">
        <template #empty> No credit cards on file. </template>
        <Column field="credit_card_no" header="Credit Card Number" style="min-width: 12rem">
          <template #body="slotProps">
            {{ formatCardNumber(slotProps.data.credit_card_id) }}
          </template>
        </Column>
        <Column field="credit_card_exp" header="Expiry Date" style="min-width: 16rem">
          <template #body="slotProps">
            {{ formatExpDate(slotProps.data.credit_card_exp) }}
          </template>
        </Column>
      </DataTable>
      <div class="p-inputgroup mt-2">
           <small class="p-inputgroup-addon font-semibold general-label-color" :style="{ width: '104px' }">Amount</small>
          <InputText
            autofocus
            v-model="this.amount"
            id="check"
            class="text-sm"    
          />
        </div>
      <template #footer>
        <Button v-if="this.success" @click='submitPayment'>Submit</Button>
      </template>
    </Dialog>
    <Dialog v-model:visible="displayCheckModal" modal header="Enter Check Number">
      <div class="p-inputgroup mt-2">
           <small class="p-inputgroup-addon font-semibold general-label-color" :style="{ width: '104px' }">Check #</small>
          <InputText
            autofocus
            v-model="this.check"
            id="check"
            class="text-sm"     
          />
        </div>
        <div class="p-inputgroup mt-2">
           <small class="p-inputgroup-addon font-semibold general-label-color" :style="{ width: '104px' }">Amount</small>
          <InputText
            v-model="this.amount"
            id="check"
            class="text-sm"    
          />
        </div>
      <template #footer>
        <Button @click='payCheck'>Submit</Button>
      </template>
    </Dialog>
    <Dialog v-model:visible="displayCashModal" modal header="Enter Cash Amount">
      <div class="p-inputgroup mt-2">
           <small class="p-inputgroup-addon font-semibold general-label-color" :style="{ width: '104px' }">Amount</small>
          <InputText
            autofocus
            v-model="this.amount"
            id="check"
            class="text-sm"
            @keyup.enter = "this.payCash"      
          />
        </div>
      <template #footer>
        <Button  @click='payCash'>Submit</Button>
      </template>
    </Dialog>
</template>

<script>
import { defineComponent } from "vue";
import { mapGetters, mapActions, mapMutations, mapState } from "vuex";
import store from "@/store/index";
import InputText from "primevue/inputtext";
import Button from "primevue/button";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import iframeResize from "iframe-resizer/js/iframeResizer";
import Dialog from "primevue/dialog";
import PaymentService from '@/services/PaymentService'
const paymentService = new PaymentService(process.env.VUE_APP_ABSTRACTION_API);
import Column from "primevue/column";
import DataTable from "primevue/datatable";


export default defineComponent({
  name: "Pay",
  components: {
    Button,
    Dialog,
    Column,
    DataTable,
    InputText
    //LoadingSpinner,
  },
  created() {
    this.amount = this.remainingBalance();
    // populate the payment iframe url if not already populated
    if(this.payment.iframeurl == null || this.payment.iframeurl == ""){
      store.dispatch("payment/fetchPaymentInfo", {
      option: "IFRAMEURL ACHFLAG CCADRFLAG",
      Client: this.session.name,
    });
  }
  },
  updated() {
    this.amount = this.remainingBalance();
  },
  computed: {
    ...mapState(["payment","session"]),
    ...mapGetters({
      getOrder: "pos/getCurrentOrder",
      getCustomer: "customer/getCust",
      getClient: "session/getClient",
      getUser: "session/getUser",
      getTenders: "pos/getTenders"
    }),
  },
    mounted() {
    window.addEventListener("message", this.receiveToken);
  },
  directives: {
    resize: {
      beforeMount: function (el, { value = {} }) {
        el.addEventListener("load", () => iframeResize(value, el));
      },
    },
  },
  data() {
    return {
      displayCheckModal: false,
      displayCOF: false,
      displayIframe: false,
      success: false,
      successBitArr: [0, 0],
      token:'',
      expiry:'',
      tokenMessage: '',
      displayOrderSubmit: '',
      error:'',
      authorizing: false,
      iframeurl: '',
      type: '',
      tran_id: '',
      displayCashModal: false,
      check: "",
      amount: '',
    };
  },
  methods: {
    ...mapActions({
       clearOrder: "pos/clearOrder",
       setCustomer: "pos/setCustomer",
       clearCustomer: "pos/clearCustomer",
       addTender: "pos/addTender"
    }),
    remainingBalance() {
      let tenderAmount = 0
      this.getTenders.forEach((tender) => {
        tenderAmount += parseFloat(tender.check_amount)
      });
      return (Math.round((parseFloat(this.total()) - tenderAmount) * 100) / 100).toFixed(2);
    },
    total() {
      let sum = 0;
        this.getOrder.forEach((part) => {
        const quantity = part.quantity > 1 ? part.quantity : 1; 
        sum += parseFloat(part.price) * quantity || 0 ;
        sum += parseFloat(part.order_amount) || 0;
        sum += parseFloat(part.balance) || 0;
      }); 
      return (Math.round(sum * 100) / 100).toFixed(2);
    },
    formatExpDate(dateString) {
      if (!dateString.includes("/"))
        return dateString[0] + dateString[1] + "/" + dateString[2] + dateString[3];
      return dateString;
    },
    rowClicked(event) {
      this.success = true;
      this.token = event.data.credit_card_id;
      this.expiry = event.data.credit_card_exp;
    },
    payCOF() {
      this.displayCOF = true
    },
    payCash() {
      this.type = 'CA'
      let li = 1
      let invoices = []
      this.getOrder.forEach((ar) => {
          invoices.push({li: li.toString(), arid: ar.ar_id, amount: ar.amount, type: ar.type, ar_app_amt: parseFloat(ar.balance).toFixed(2).toString() })
          li++
        })
      this.addTender({
        check_amount: parseFloat(this.amount).toFixed(2).toString(),
        cust: this.getCustomer?.cust_id,
        user: this.getUser.user_id,
        tran_type: "fmp",
        payment_type: this.type,
        li_items: invoices,
      })
      //location.reload()
      this.amount = ''
      this.displayCashModal = false
    },
    payCheck() {
      this.type = 'CK'
      let li = 1
      let invoices = []
      this.getOrder.forEach((ar) => {
          invoices.push({li: li.toString(), arid: ar.ar_id, amount: ar.amount, type: ar.type, ar_app_amt: parseFloat(ar.balance).toFixed(2).toString() })
          li++
        })
      this.addTender({
        check_amount: parseFloat(this.amount).toFixed(2).toString(),
        cust: this.getCustomer?.cust_id,
        // register_no: this.getRegister.reg_id,
        user: this.getUser.user_id,
        tran_type: "fmp",
        payment_type: this.type,
        check: this.check,
        li_items: invoices,
      })
      this.amount = ''
      this.check = ''
      //location.reload()
      this.displayCheckModal = false
    },
    submitPayment() {
      let li = 1
      this.type = 'CC'
      let invoices = []
      this.getOrder.forEach((ar) => {
          invoices.push({li: li.toString(), arid: ar.ar_id, amount: ar.amount, type: ar.type, ar_app_amt: parseFloat(ar.balance).toFixed(2).toString() })
          li++
        })
      let creditcard_no_items = [{credit_card_id: this.token, creditcard_exp: this.expiry, creditcard_amt: this.amount}]
      this.addTender({
        check_amount: parseFloat(this.amount).toFixed(2).toString(),
        cust: this.getCustomer?.cust_id,
        user: this.getUser.user_id,
        tran_type: "fmp",
        payment_type: this.type,
        li_items: invoices,
        creditcard_no_items: creditcard_no_items
      })
      this.amount = ''
      this.check = ''
      //location.reload()
      this.displayCOF = false
      this.displayIframe = false
    },
    formatStringDate(dueDate) {
      const reformatedDueDate = new Date(dueDate)
        ?.toLocaleString("en-US", {
          day: "2-digit",
          month: "2-digit",
          year: "2-digit",
        })
        .split("/")
        .join("-");

      return reformatedDueDate
    },
    formatCardNumber(card_id) {
      return "************" + card_id.substring(12, 16);
    },
    payCardNotPresent() {
      this.displayIframe = true;
      this.type = 'CC'
    },
    payCC() {
      this.authorizing = true
      this.type = 'CC'
      this.submitPayment()
    },
    receiveToken(event) {
      if (event.origin.includes("gateway.total-computing.com")) {
        var token = event.data;
        if (token.success) {
          this.success = true;
          this.token = token.message;
          this.expiry = token.expiry;
        } else {
          this.tokenMessage = token.errorMessage;
        }
      }
    }
  },
});
</script>
